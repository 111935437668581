@charset "utf-8";
@import "normalize";

body {
  background: #ECC561;
  text-align: center;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
}

#number {
  color: rgba(0,0,0,0.6);
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 10em;
  font-weight: 400;
  margin: 0;
}

#street {
  color: rgba(0,0,0,0.9);
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2em;
  font-weight: 400;
  margin: 0;
}

#latitude, #longitude {
  color: rgba(0,0,0,0.4);
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
  font-weight: 400;
  margin: 0;
  display: inline;
}
